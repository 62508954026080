// NOTE 页面局部使用的状态管理

import Cookies from 'js-cookie';
import { getLanguage } from '@/lang/index';

const state = {
  language: getLanguage(),
  classifyID: 0, // 市场分类
};

const mutations = {
  SET_LANGUAGE: (state, language) => {
    state.language = language;
    Cookies.set('language', language);
  },
  SET_classifyID: (state, classify) => {
    state.classifyID = classify;
  }
};

const actions = {
  setLanguage({ commit }, language) {
    commit('SET_LANGUAGE', language);
  },
  setClass({ commit }, classify) {
    commit('SET_classifyID', classify);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
