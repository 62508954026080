
// 登录模块接口
import { $get, $post, $put } from '@/utils/request'

export default {
  // 登录
  login(data) {
    return $post('/NFT/user/userManageLogin', data)
  },
  // 新增管理员账号 
  addSysuser(data) {
    return $post('/NFT/user/addSysuser', data)
  },
  // 管理员账号查询 
  pageQuerySysUser(data) {
    return $post('/NFT/user/pageQuerySysUser', data)
  },
  // 管理员账号查询 
  modifySysUser(data) {
    return $post('/NFT/user/modifySysUser', data)
  },
  // 重置密码 
  resetPwd(data) {
    return $post('/NFT/user/resetPwd', data)
  },
  //个人中心
  queryUserDetail(data) {
    return $post('/NFT/user/queryUserDetail', data)
  },
    //个人中心修改密码
    updatePwd(data) {
      return $post('/NFT/user/updatePwd', data)
    },
  //获取 验证码
  getLoginCaptcha(data) {
    return $post('NFT/user/getLoginCaptcha', data)
  },
}