const state = {
  //用户登录后的基本信息
  userInfo: {
    lang: 'zh',
    loginState: 0,
    chainId: 0,
    token: "",
    owner: "",
  }
};

const mutations = {
  changeUserInfo(state, value) {
    console.log(state,value);
    state.userInfo = { ...state.userInfo, ...value };
  },
  clearUserInfo(state,value) {
    state.userInfo.token = ''
    state.userInfo.loginState = 0
  }
};

export default {
  namespaced: true,
  state,
  mutations,
};
