// 字符模块接口
import { $get, $post, $put } from "@/utils/request";

export default {
  // 字符列表
  wordsList(data) {
    return $post("/NFT/character/queryCharacter", data);
  },
  // 字符分类
  wordsKindList(data) {
    return $post("/NFT/character/queryCharacterCategory", data);
  },
  //删除字符
  deleteCharacter(data) {
    return $post("/NFT/character/deleteCharacter", data);
  },
  //编辑字符
  modityCharacter(data) {
    return $post("/NFT/character/modityCharacter", data);
  },
  //编辑字符分类
  modifyCharacterCategory(data) {
    return $post("/NFT/character/modifyCharacterCategory", data);
  },
  //新增字符分类
  addCharacterCategory(data) {
    return $post("/NFT/character/addCharacterCategory", data);
  },
  //删除字符分类
  deleteCharacterCategory(data) {
    return $post("/NFT/character/deleteCharacterCategory", data);
  },
  //删除字符分类
  modifyCharacterByCategory(data) {
    return $post("/NFT/character/modifyCharacterByCategory", data);
  },
  //编辑字符排序
  modifyCharacterSort(data) {
    return $post("/NFT/character/modifyCharacterSort", data);
  },
  //批量下载
  getDownLoad(data) {
    return $get("/NFT/character/donwloadCharacter?id=" + data);
  },
  //创作内容查询
  getCharacterText(data) {
    return $post("/NFT/character/pageQueryCharacterText", data);
  },
  //删除创作内容
  deleteCharacterText(data) {
    return $post("/NFT/character/deleteCharacterText", data);
  },
  //创作内容详情
  detailCharacterText(data) {
    return $post("/NFT/character/detailCharacterText", data);
  },
//创作内容精选更新
updateCharacterText(data) {
  return $post("/NFT/character/updateCharacterText", data);
},
//字符排序
modifyCharactersSort(data) {
  return $post("/NFT/inner/character/modifyCharactersSort", data);
},
};
