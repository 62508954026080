// regular rules
import i18n from '@/lang';
import store from '@/store'

const xxReg = '' //



// custom validation function

// 校验网站规则选择框
const validateAgreementcheckBox = (rule, value, callback) => {
  if (value === false) {
    callback(new Error(i18n.t('login.plea_read_firt')))
  } else {
    callback()
  }
}
// 校验铸造标题
const validateTitle = (rule, value, callback) => {
  if (!value.length) {
    callback(new Error(i18n.t('Single.titl_cann_empt')))
  } else {
    if (value.length < 2 || value.length > 32) {
      callback(new Error(i18n.t('Single.leng_betw_char')))
    }
    callback()
  }
}
// 校验新密码
const passWord = (rule, value, callback) => {
  if (!value.length) {
    callback(new Error('密码不能为空 '))
  } else {
    if (!( /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/).test(value)) {
      if(value.length < 8){
        callback(new Error('密码不能少于8位'))
      }else if(value.length > 20){
        callback(new Error('密码不能大于20位'))
      }else{
        callback(new Error('请输入数字+字母组合'))
      }
    }
    callback()
  }
}


// 校验上传的file
const validateFile = (rule, value, callback) => {
  const allowType = {
    "1": ["image/svg+xml"],
    "4": ["image/svg+xml", "image/png", "image/jpeg", "video/mp4"],
    "5": ["image/svg+xml", "image/png", "image/jpeg", "video/mp4"]
  }
  let sign = store.state.creation.params.type;
  let fileType = value.type
  if (!value) {
    callback(new Error(i18n.t('Single.file_uplo_cann')))
  } else {
    const checkType = allowType[sign].includes(fileType)
    if (!checkType) {
      callback(new Error('文件格式不正确'))
    }
    callback()
  }
}

// 校验验证码
const validateEmailAuth = (rule, value, callback) => {

}

// 全局校验规则
const Rules = {
  email: [
    { required: true, message: '请输入账号/邮箱', trigger: "blur" },
  ],
  password: [
    { required: true, message: '请输入密码', trigger: "blur" },
    { min: 6, message: '密码长度过短', trigger: ["blur"] },
  ],
  // 验证码
  code: [
    { required: true, message: '请输入验证码', trigger: "blur", whitespace: false },
    { min: 4, max: 8, message: '验证码不正确', trigger: 'blur' }
  ],
  type: [
    { required: true, validator: validateAgreementcheckBox, trigger: 'change' }
  ],
  type: [
    { required: true, validator: validateAgreementcheckBox, trigger: 'change' }
  ],
  // type: { required: true, message: i18n.t('page.sele_cast_type'), trigger: 'change' },
  // 首页弹出层表单校验
  chapter: { required: true, message: i18n.t('page.please_sele_chap'), trigger: 'change' },
  part: { required: true, message: i18n.t('page.plea_sele_char'), trigger: 'change' },
  level: { required: true, message: i18n.t('page.plea_sele_char'), trigger: 'change' },
  // 铸造页面表单校验
  title: { required: true, validator: validateTitle, trigger: 'blur' },
  charactertype: { required: true, type: "array", message: i18n.t('Single.plea_sele_cate'), trigger: 'change' },
  // file: { required: true, validator: validateFile, trigger: 'change' },
  file: { required: true, validator: validateFile, trigger: 'change' },
  newPassWord:{required: true, validator: passWord, trigger: 'blur'}
}










export default {
  //验证规则
  Rules,

  // 验证方法
  // validatePass
}