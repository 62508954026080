
import axios from 'axios'
import { Message } from 'element-ui'
import store from '@/store'
import i18n from '@/lang';
import router from '../router'

const env = process.env.NODE_ENV
let baseURL = process.env.VUE_APP_BASEURL

export function request(config) {
  const instance = axios.create({
    baseURL: baseURL,
    withCredentials: false, // 表示跨域请求时是否需要使用凭证
    timeout: 20000,
  })

  // 请求拦截器
  instance.interceptors.request.use(config => {
    // Token校验
    if (!config.data) {
      config.data = {}
    }
    const user = store.state.user.userInfo
    if (user && user.token) {
      config.data.token = user.token
      // config.headers.token = user.token
    }
    return config
  }, err => Promise.reject(err))

  // 响应拦截器
  instance.interceptors.response.use(res => {
    if (res.data.code != 0) {
      if (res.data.code == 104) {
        Message({
          message: '登录过期',
          type: 'warning'
        });
        router.push('/login');
      }else{
        Message({
          message: `${res.data.msg}`,
          type: 'error'
        });
      }
    } 
    return res
  }, err => {
    return Promise.reject(err)
  })
  return instance(config)
}


export function $get(url, data) {
  return request({
    url: url,
    method: 'get',
    params: data
  })
}

export function $post(url, data) {

  return request({
    url: url,
    method: 'post',
    data
  })
}
export function $put(url, data) {
  return request({
    url: url,
    method: 'put',
    data
  })
}