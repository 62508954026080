import Vue from "vue";
import VueI18n from "vue-i18n";
import Cookies from "js-cookie";
import enLocale from "./en";
import zhLocale from "./zh";

Vue.use(VueI18n);

const messages = {
  en: {
    ...enLocale
  },
  zh: {
    ...zhLocale
  }
};

export function getLanguage() {
  const chooseLanguage = Cookies.get("language");
  if (chooseLanguage) return chooseLanguage;
  const language = (
    navigator.language || navigator.browserLanguage
  ).toLowerCase();
  const locales = Object.keys(messages);

  for (const locale of locales) {
    if (language.indexOf() > -1) {
      return localStorage.getItem("language");
    }
  }
  return localStorage.getItem("language") || 'en';
}


const i18n = new VueI18n({
  locale: getLanguage(),
  // fallbackLocale: 'en',
  formatFallbackMessages: true,
  messages
})

export default i18n;
