export default {
    lang: {
        Language: "Language",
        zh: "中文",
        en: "English"
    },
    example: {
        detailedTutorial: "Detailed tutorial",
        dateFormatting: "Date formatting"
    },
    header: {
        switchText: 'Switch to a different wallet',
        connectionText: 'connection',
    },
    login: {
        Register: 'Register New Lion NFT Account',
        Register_tips: 'Register using email',
        Login: 'Login to Lion NFT',
        Email_label: 'mailbox ',
        Email_placholder: 'Email Address',
        Password_label: 'Password',
        Password_placholder: 'Please enter your password ',
        Password_forgot: 'Forgot your password?  ',
        Valid: 'Please enter a verification code ',
        Code_label: 'Enter Code ',
        Code_placholder: 'Enter the verification code',
        Code_get: 'Get verification code',
        Sign: 'Sign in ',
        Tips: "Don't have an account yet? ",
        ToRegister: 'Register now',
        Reset_title: 'Reset password',
        Reset_tips: 'Enter your email address to reset your password',
        Reset_button: 'Reset',
        Register_button: 'Register',
        i_have_read: "I have read and agree to the terms of service and the licensing policy",
        and_the_lice: "and the licensing policy",
        plea_inpu_addr: "Please input the email address",
        plea_vali_addr: "Please enter a valid email address",
        plea_veri_code: "Please enter a valid verification code",
        code_is_char: "code is 4 for characters",
        plea_ente_pass: "Please enter the password",
        plea_read_firt: "Please read and agree first",
        Privacy_Notice: 'Privacy Notice',
        back: "Back",
        e_wallet: 'Please log in to your account first.',
        The_wallet_is_in_use: 'The wallet is in use'
    },
    home: {
        home: "Home",
        search: "Search by item or collection",
        Marketplace: "Marketplace",
        Account: "Account",
        Create: "Ceate",
        metawords: "Metawords",
        LAN: "BSC Mainnet",
        LoginWallet: "Link wallet",
        title: "Discover, collect, and sell extraordinary NFTs",
        content: "One of the largest NFT marketplace in the world",
        buttons: "Create",
        NameSwiper: "Exclusive Meta World drops",
        author: "Best-selling author",
        introduce: "Create and sell your NFTs",
        wallet: "wallet",
        walletintroduce: "Once you’ve set up your wallet of choice, connect it to Meta World by clicking the wallet icon in the top right corner",
        collect: "Create your collection",
        collectintroduce: "Edit your social links, description, profile and background picture, click Create to set up your portfolio, and set the sales fee.",
        establish: "Add your Metawords NFT",
        establishintroduce: "Upload your work (image, video, audio, or 3D art), add a title and description, and customize your NFTs with propeties, stats, and unlockable content.",
        sale: "List them for sale",
        saleintroduce: "Choose between auctions and fixed price lists. The way you choose to sell NFTs, we help you sell them!",
        bazaar: "Explore the Browse",
        Invitealliance: "Invite alliance",
        email: "If you and your team want to publish and push NFT, please contact service@lionnft.io",
        bottom1: "About Us",
        bottom2: "White Paper",
        bottom3: "Audit Report",
        bottom4: "Company Vision",
        bottom5: "Terms of Service",
        bottom6: "Privacy Policy",
        bottom7: "Legal Notice",
        bottom8: "Site Navigation",
        bottom1_href: "https://app.gitbook.com/s/-MhWQ1AKe2k1Wxfob6AM/",
        bottom2_href: "#",
        bottom3_href: "#",
        bottom4_href: "https://app.gitbook.com/s/-MhWQ1AKe2k1Wxfob6AM/company-vision",
        bottom5_href: "https://app.gitbook.com/s/-MhWQ1AKe2k1Wxfob6AM/untitled",
        bottom6_href: "https://app.gitbook.com/s/-MhWQ1AKe2k1Wxfob6AM/lionnft-privacy-policy",
        bottom7_href: "https://app.gitbook.com/s/-MhWQ1AKe2k1Wxfob6AM/lion-nft-legal-notice",
        bottom8_href: "#",
        view: "Views",
        items: "Items",
        owners: "Owners",
        floo_pric: "Floor Price",
        high_pric: "Highest Price",
        xubind_describe: "MetaWords Character NFTs inherit their appearance from the Book from the Ground and will be minted as NFTs. MetaWords characters are basic units of the MetaWords language system–the singlar characters that make up its writing system. Collected from all over the world, these symbols are organized, and formatted by Xu Bing, who has worked on pictographic symbols from 2003 to present. Every symbol has experienced the change of its era. Symbols undergo tireless revisions and adjustments to ensure they are consistent with the language system before they can be incorporated into the MetaWords lexicon.",
        verified: "verified",
        my_prof: "My profile",
        my_shar: "My shards",
        Inhabitant_program: "Inhabitant program",
        log_out: "Log out",
        meta_char_nft: "MetaWords Character NFTs",
        original_nft: "MetaWords Original NFTs",
        char_desc: "The singular character and the basic unit that makes up the exciting universe of MetaWords.",
        meta_shar_nft: "MetaWords Shard NFTs",
        trad_exce_draw: "Trade excerpts drawn from Xu Bing's artwork.",
        comi_soon: "Coming Soon",
        meta_orig_nft: "MetaWords Original NFTs",
        crea_orig_nft: "Create original NFT works of your own using MetaWords Character NFTs.Unlock infinite possibilities of the language of MetaWords!",
        meta_blin_boxe: "MetaWords Blind Boxes",
        in_each_meta: "In each MetaWords Blind Box, you will get one of the following NFTs:",
        a_meta_char: "A MetaWords Character NFT.",
        the_sing_char: "The singular character and the basic unit that makes up the visual language system of MetaWords.",
        or: "OR",
        a_meta_shar: "A MetaWords Shard NFT.",
        a_shor_piece: " A short piece of exerpt drawn from Xu Bing's artwork,Book From the Ground, which could be used to put classic chapters of the book back together in the future.",
        left: "left",
        purc_link: "Purchase Link Coming Soon",
        join_our_disc: "Join Our Telegram to get notified",
        crea_your_meta: "Create Your MetaWords Orginal NFT",
        resour_for_gett: "Resources For Getting Started",
        meta_mark: "MetaWords Market",
        lion_nft_rese: "©Lion NFT 2021, All Rights Reserved.",
        suipian_nft: `MetaWords Shard NFTs are the extensions of artist Xu Bing's artwork Books from the Ground within the Metaverse. The book tells a story of an ordinary white-collar worker through his 24-hour daily lifecycle, recounted hour by hour, each hour composing a chapter. Shard NFTs are drawn from 35 chapters, which correspond to the 24-hour chapters from the artwork Book from the Ground: From Point to Point. Each chapter is composed of its own set of Shard NFTs. Eventually, all 35 Chapter NFTs can be assembled to create a complete Book from the Ground NFT, a brand new NFT artwork derived from the artwork Book from the Ground.`,
        yuanchuang_nft: `MetaWords Original NFTs are NFTs created by users using the creation-tool based on MetaWords language system. The creation-tool includes all the MetaWords characters that have been released on the market, and as the project progresses, more MetaWords characters will be added into the dictionary of the creation-tool. The platform does not charge any fees when users use the creation-tool, ensuring that every Metaverse inhabitant is able to access and play with the MetaWords language system.`,
    },
    Marketplace: {
        Characters: 'MetaWords Character NFT',
        Shards: 'MetaWords Shard NFT',
        Create: 'MetaWords Original NFT'
    },
    bazaar: {
        title: "Browse",
        jiazai: "Load More",
        meiyou: "No More",
        no_data_baza: "No data",
    },
    personalCenter: {
        edit: "Edit profile",
        creaTed: "CREATED",
        bought: "PURCHASED",
        favorites: "FAVORITES",
        sold: "HIDDEN",
        waitPay: "PENDING PAYMENTS",
        onsale: "ON SALE",
        blindBox: "BLIND BOXES",
        history: "TRADING HISTORY",
        verify_account: "Verify your account",
        collected: 'COLLECTED',
        kyc: 'KYC Verified',
        even_type: "Event Type:",
        all: "All",
        purc: "Purchased",
        sole: "Sold",
        offe: "Offers",
        paym: "Payment",
        mint: "Minted",
        list: "Listed",
        even: "Event",
        item: "Item",
        pric: "Price",
        from: "From",
        to_per: "To",
        date_per: "Date",
        name_cann_empt: "Name cannot be empty",
    },
    establish: {
        sure: "Sure",
        go: "Go back",
        Create: "Create collectible",
        jieshao: "Choose “Single” if you want your collectible to be one of a kind or “Multiple” if you want to sell one collectible multiple times",
        dan: "Single",
        duo: "Multiple",
        weiqi: "casting Weiqi",
        zijin: "We do not own your private keys and cannot access your funds without your confirmation",
        tishi: "prompt",
        tihsi1: "1. Download the SGF file before proceeding to the next step",
        tishi2: "2. Click 'jump over' to upload your own SGF file",
        quxiao: "jump over",
        xiayibu: "Next step",
        dishu: "Metawords",
        dishuTitle: "Mint Your MetaWords NFT",
        type_select: 'Please Select A Category',
        please_select: 'please select',
        tags: "tags",
        addTag: "+add tag",

    },
    Single: {
        fanhui: "Back to previous",
        dan: "Create single collectible",
        qipu: "Create Weiqi NFT",
        duoge: "Create Multiple collectible",
        shangchuan: "Upload file",
        yulan: "Preview",
        xuanze: "Choose file",
        toufnag: "Put on marketplace",
        yulan1: "Upload file to preview your brand new NFT",
        shurujiage: "Enter price to allow users instantly purchase your NFT",
        jiesuo: "Unlock once purchased",
        tijiao: "Create item",
        price: "price",
        PleasePrice: "Please enter the price",
        Title: "Title",
        PleaseTitle: "Please enter a name",
        Description: "Description",
        PleaseDescription: "Please enter a description",
        Royalties: "Royalties",
        Properties: "Properties (Optional)",
        Supply: "Supply",
        Approve: "Approve token",
        Create: "Create Your NFT",
        Checking: "Approve wallet transactions",
        Done: "Done",
        Progress: "In Progress...",
        Start: "Start",
        toKen: "Upload files & Mint token",
        method: "Call contract method.<br/>This process requires a corresponding gas fee",
        order: "Sign sell order",
        orderusing: "Approve sale orders using your wallet.<br/>If you refuse to sign, you will cancel this minting process and the gas fee you paid will not be refunded.",
        weiqiname: "Weiqi Name",
        heiqiname: "Black chess Name",
        heiqiduanwei: "Black chess dan grading",
        baiqiname: "White chess Name",
        baiqiduanwei: "White chess dan grading",
        PleaseWeiqiname: "The Weiqi name must not be empty",
        Pleaseheiqiname: "A Black chess player cannot be empty",
        Pleaseheiqiduanwei: " Black chess dan grading position cannot be empty",
        Pleasebaiqiname: "A White chess player cannot be empty",
        Pleasebaiqiduanwei: "White chess dan grading position cannot be empty",
        titl_cann_empt: "Title cannot be empty",
        leng_betw_char: "The length is between 2 and 32 characters",
        file_uplo_cann: "The file to be uploaded cannot be empty",
        plea_sele_cate: "Please select the category",

    },
    redactUser: {
        fanhui: "Back",
        xuanze: "Upload Image",
        recommend: "Minimum Recommended Image Size 400x400 GIF Compatible. ",
        Edit: "Edit profile",
        EditDescription: "You can edit your preferred display name and upload your profile picture.",
        name: "Display name",
        PleaseName: "Enter yuor display name",
        URL: "Custom URL",
        PleaseURL: "Enter your custom URL",
        Bio: "My Profile",
        PleaseBio: "Tell us about yourself in a few words",
        Update: "Update profile",
        img_describe: 'Please only upload JPG or PNG type.',
        Image_format_error: 'Uploaded file is not a valid image. Only JPG and PNG files are allowed.',
        notName: 'The name cannot be empty',
        length: 'Contains 2 to 16 characters'
    },
    details: {
        approve: "Meta World Certification",
        Owned: "Owned by",
        available: "available",
        creator: "creator",
        own: "own",
        Transaction: "Transaction Record",
        user: "user",
        time: "time",
        perating: "perating",
        product: "product description",
        About: "About NFT",
        Approved: "Approved",
        approvedNoW: "Approve Now",
        Biding: "Biding",
        BidNow: "Bid Now",
        Buying: "Buying",
        BuyNow: "Buy Now",
        UnSale: "Sale Out",
        OnSale: "OnSale",
    },
    dishu: {
        baseDS: "Base Dishu",
        mintDS: "Mint Dishu"
    },
    messages: {
        ltbalance: "Unable to process payment: Low account balance",
        acquiree: "NFT has been purchased",
        have_offer: "Current price is available",
        reject: 'User rejects transaction',
        failure: 'Transaction failure',
        mintingCompleted: 'Minting Completed',
    },
    Identity: {
        Verification: "Identity Verification",
        describe: `Please ensure the accuracy of entered information to avoid failing KYC verification.`,
        nati: "Nationality",
        firs_name: "First Name",
        last_name: "Last Name",
        ente_name: "Enter First Name",
        ente_last_name: "Enter Last Name",
        cont: "Continue",
        pass: "Passport",
        id_numb: "ID number",
        card_id: "Card ID",
        upload_imag_of: "Upload Image of ID Card",
        submit: " Submit",
        please_select_country: 'Please select country'
    },
    document: {
        type: "Document Type",
        describe: "Please ensure the accuracy of entered information to avoid failing KYC verification",
        idcard: "Government-Issued Identification  Document"
    },
    passport: {
        upload_image: "Upload an image of the DATA PAGE of your Passport",
        good: "Good",
        cropped: "Cropped/Missing Details",
        Blurry: "Unclear/Blurry",
        descrie: "File size must be less than 5MB and in .jpg or .png format.",
        image: "Upload data page of your passport",
        front: "Upload Front of your ID",
        back: "Upload Back of Your ID",
        result: "Identity Verification",
        result_descrie: "It may take up to 24 hours to verify your Identification. We will notify you via email once verification has been completed."
    },
    wallet: {
        address: "Your wallet address",
        bind: "Connect",
        unbind: 'Disconnect',
        bala: " Balance",
        conv: "Convert",
        conn_with_meta: "Connect with MetaMask to securely store your digital assets and crypto",
        wall_conn_bina: "Please connect your MetaMask wallet and switch your network to BSC Main Net.",
        plea_main_netw: "Please switch your network to BSC Main Net.",
        plea_test_netw: "Please switch to the BSC Testnet network.",
        hava_unlo_wall: "You have not unlocked BSC wallet!",
        wall_conn_succ: "Your wallet have been successfully connected. Please complete the registration process to continue using the website.",
        copy: "Copy",
    },
    convert: {
        conv_toke: "Convert tokens",
        from: "From",
        amount: "Enter the amount you'd like to convert",
        receive: "To",
        receive_two: "you will receive",
        convert_sure: "Convert",
        cane: "Cancel",
    },
    sell: {
        title: "Select your sale method",
        auction_describe: "Sell to the highest bidder",
        expiration_describe_one: "Your auction will automatically end at this date and time.",
        expiration_describe_two: "The highest bidder will win. No need to cancel the transaction.",
        learn: "Learn More",
        learn_describe: 'If someone makes a bid in the last 5 minutes of an auction, the auction will be extended by 5 minutes.',
        bid_deposit: "Bid deposit",
        Bid_Deposit_two: "Bidding Deposit",
        bid_deposit_describe: "Set the deposit amount you’d like the bidder to place as a hold for the item.",
        paytime: "Final payment time",
        paytime_describe: "The winning bidder is required pay the balance due after bidding has ended or risk forfeiting their deposit.",
        token_button: "Token approved",
        shelf_time: "Choose time",
        day: "days",
        Set_Price: 'Set Price',
        Set_the_price: "Sell at a fixed or declining price",
        Sell_at_a_fixed_price: 'Sell at a fixed price',
        Auction: 'Auction',
        Highest_Bid: 'Highest Bid',
        Highest_Bid_describe: 'Auction to the highest bidder',
        Bundle: 'Bundle',
        Bundle_describe: 'Group this item with others to sell',
        Set_Price_describe: 'Listing will be available until transaction is cancelled or asset is transferred.',
        Schedule_for_a_future_time: 'Schedule for a future time',
        Schedule_for_a_future_time_describe: 'You can schedule to list this item for sale at a future/custom date and time.',
        Summary: 'Summary',
        Bid_Deposit: 'Bid Deposit',
        price: 'Price',
        Approve_Meta: 'Approve WBNB',
        Minimum_Bid: 'Minimum Bid',
        Minimum_Bid_describe: 'Set your starting bid price. (Minimum bid must ≥ 0.01 WBNB)',
        Expiration_Date: ' Please fill in the expiration date and time.',
        Bidding_up: 'Bidding up',
        Bidding_up_describe: 'Set your minimum markup(Bidding up must >= 0.01WBNB)',
        Please_enter_the_content: 'Please Enter The Content',
        Post_listing: 'Post listing',
        amou: "Amount",
        your_bala: "Your balance:",
        curr_pric: "Current Price:",
        bidd_up: "Bidding up:",
        chec_this_agre: "By checking this box, I agree to LionNFT`s",
        term_serv: "Terms of Service",
        subm_bid: "Submit Bid",
        canc: "Cancel",
        gift_title: "Transfer",
        to_addr_nonull: "The recipient address cannot be empty",
        to_addr_iserror: "Recipient address is invalid",


    },
    auction: {
        gift: 'Gift',
        sell: 'Sell',
        market: "Cancel auction",
        approved: "Approve WBNB",
        approve: "Approve WBNB",
        place: "Place Bid",
        event: 'Event',
        price: 'Price',
        from: 'From',
        to: 'To',
        date: 'Date',
        you_maybe_like: 'You may also like:',
        remove_from_market: 'Remove from market',
        place_bid: 'Buy Now',
        Description: 'Description',
        Owned_by: 'Owned by',
        Properties: 'Properties',
        Detail: 'Detail',
        Contract_Address: 'Contract Address',
        Token_ID: 'Token ID',
        Token_Standard: 'Token Standard',
        Auction_Has_Ended: 'Auction has ended',
        Offers: 'Offers',
        Payment_time: 'Payment time',
        crea_by: "Created by",
        Trading_History: 'Trading History',
        Payment_is_due_in: 'Payment is due in:',
        Final_payment_time_ends_in: 'Final payment time ends in:',
        pay_now: "Pay Now",
        plea_ente_amou: "Please enter the purchase amount",
        bid_less_than: "Bid no less than",
        plea_corr_form: "Please enter the amount in the correct format",
    },
    hint: {
        loginSuccess: 'Successfully Logged In',
        walletDifferent: "The wallet you have bound is not the same wallet you are attempting to connect to.",
        conversionSuccess: 'Successfully converted',
        conversionFailure: 'Conversion Failure',
        formalError: 'Formal Error',
        amountError: 'Amount Error',
        successfullyPurchased: 'Successfully Purchased',
        successfullyUnlisted: 'Successfully Unlisted',
        login: 'You are not logged in, or your wallet is not connected.',
        kyc: 'Please complete the Identity Verification first.',
        walletErr: 'Please switch to the wallet address which is connected to your account.',
        copyOK: 'Copy Success',
        not_bind_wallet: 'Your wallet is disconnected.',
        not_login: 'Please log in to your account first.',
        networkId: 'Inconsistent network environment'
    },
    footer: {
        ABOUT_US: 'ABOUT US',
        AUDIT_REPORT: 'AUDIT REPORT',
        privacy: 'Privacy',
        terms: 'Terms',
        introduce: "Lion NFT market platform primarily focuses on investment and innovation of digital assets. We provide global & regulated services, such as digital asset exchange, NFT innovation, issuance, and trading."
    },
    prompt_language: {
        wall_succ: "Wallet successfully disconnected. ",
        not_log: "Not logged in wallet",
        wall_bind_succ: "Wallet successfully connected. ",
        wall_bound: "Wallet already connected.",
        log_succ: "Login succeeded",
        log_wall_not_bound: "The login wallet is inconsistent with the bound wallet!",
        input_email: "Please input the email address",
        verifi_sent: "Verification code sent",
        register_was_succ: "register was successful",
        account_al_exis: "The account already exists or the verification code is wrong",
        input_address: "Please input the email address",
        reset_succ: "Reset successful",
        reset_faild: "Reset failed",
        upload_faild: "Upload failed",
        upload_succeeded: "Upload succeeded",
        upload_waiting: "Upload succeeded! Waiting for certification!",
        no_valid_obtained: "No valid data was obtained",
        unwallet: "Your wallet is not connected.",
        copy_succ: "Copy succeeded",
        credit_low: "Sorry, your credit is running low",
        insuff_inven: "You already own this item",
        purc_succ: "Purchase successful",
        low_cert_un_pur: "Low certification level! Unable to purchase!",
        ple_fil_corr: "Please fill in the correct bidding price",
        insu_acco_bala: "Insufficient account wbnb balance",
        succ_bidd: "Successful bidding",
        inco_orde: "Incorrect order",
        uscc_auct: "Successful auction",
        sale_price_inva: "sale price invalid",
        conm_sale: "Completion of sale",
        plea_logi: "Please login",
        off_shel: "Off shelf failure",
        agre_lion_term: "I agree to LionNFT`s Terms of Service",
        auth_deni: "Authorization denied",
        inco_pers_info: "Incomplete personal information",
        no_perm: "No permission",
        error: "error",
        dele_succ: "Delete succeeded",
        uplo_form_does: "The upload format does not meet the requirements",
        NFT_crea_whit: "NFT cannot be created because it is not on the whitelist",
        only_auth_acco: "only author account mint",
        ther_unfi_item: "There are unfinished items",
        inco_book: "Incorrect book type",
        crea_comp: "Creation complete",
        bid_no_less: "Bid no less than",
        lang_bidd_cont: "The land bidding contract is not authorized",
        pric_cann_be: "Price cannot be blank",
        crea_succ: "Created successfully",
        off_shel_fail: "Off shelf failure",
        remo_succ: "Removed successfully",
        agee_lion_term: "I agree to LionNFT`s Terms of Service",
        plea_sele_book: "Please select more than 3 Book elements",
        dupl_book_plea: "Duplicate book, please re select",
        avat_canno: "Avatar cannot be empty",
        coll_succ: "Added to My Favorite.",
        coll_fail: "Collection failed",
        plea_uplo_pict: "Please upload the picture",
        plea_fill_info: "Please fill in the information",
        wallet_addr_mony: "Conversion failed. Please switch to the wallet address which is connected to your account.",
        error_10001: " There are bidders who have already made offers.",
        error_10500: "already have bidders",
        transaction: "transaction failed",
        logi_fail: " Incorrect password",
        acco_not_exis: "account is not exist",
        selectTime: "Please select a time you want to list this item for sale.",
        placeholder: "select time slot",
        lack_time: "The time you selected is not available",
        signature_failure: "You have cancelled your listing",
        transaction_signature_failure: "User denied transaction signature",
        failure: "The operation failure",
        repeat_choose: "Please only select one Shard for the corresponding paragraph.",
        canc_coll: "Cancel collection",
        warn_tip: 'Please confirm that you have WBNB in your wallet. Or you can click the "wallet" icon in the upper right corner to redeem WBNB.',

    },
    table: {
        no_data: "No offers yet"
    },
    symbols: {
        filt: "Filter",
        clea_filt: "Clear filter",
        stat: "STATUS",
        fixe_pric: "Fixed Price",
        on_auct: "On Auction",
        pric: "PRICE",
        appy: "Apply",
        sear_crea_coll: "Search by creator collectible or collection",
        pric_low_high: "Price:Low to High",
        pric_high_low: "Price:High to Low",
        newly_listed: "Time:Newly listed",
        sort_orde: "sort order",
        type_symb: "type",
        results: 'results',
        min: "Min",
        max: "Max"
    },
    bind: {
        conn_your_wall: "Connect Your Wallet",
    },
    kyc_authentication: {
        cong: "Congratulations!",
        acco_has_veri: "Your account has been verified",
    },
    shards_page: {
        shard_title: "My Shards",
        create_mana_shar: "Create, curate, and manage collections of unique NFTs to share and sell",
        shard: "Shards",
        chap: "Chapters",
        asse_into_norm: "Assemble into normal chapter",
        asse_into_prem: "Assemble into Premium Chapter",
        appl_for_buyb: "Apply for Buyback",
    },
    operation: {
        back: "Back",
        preview: "preview",
        cont_comp_pict: "Content can only be composed of pictures",
        cast_char_less1: "You need at least 1 characters to mint your NFT (excluding Punctuation and Trademark)",
        cast_char_less2: "You need at least 4 characters to mint your NFT (excluding Punctuation and Trademark)",
        pict_load_fail: "Picture loading failed",
        plea_ente_cont: "Please enter the content",
        copi_char_exce: "The copied character exceeds the maximum limit",
        numb_copi_exce: "The number of copies exceeds the maximum limit",
        cont_reac_maxi: "Content has reached the maximum limit",
        maxi_numb_char: "The maximum number of characters has been reached",
        canc: "Cancel",
        cast: "Mint",
        first_trad_lion: "*If this is your first-time trading on Lion NFT, we will require authorization to access your NFT and initiatethe transaction process,which will require a one-time gas fee.",
    },
    blindBox: {
        received_order: 'We received your order!',
        purchase1: 'Are you sure to purchase 1 MetaWords Blind Box with ',
        purchase2: ' BNB?',
        Buying_tips1: 'Thank you for your purchase!',
        Buying_tips2: 'Please wait for the platform to process your order',
        Buying_tips3: 'You will find the item you purchased in',
        Buying_tips4: '"My Profile>Blind Box"in about 5 minutes.',
        Sure: 'Sure',
        Cancel: 'Cancel',
        Okay: 'Okay!',
        Purchase_failed: 'Purchase failed',
        Purchase_succeeded: 'Purchase succeeded',
        blind_box_num: 'Insufficient number of blind boxes',
        not_sufficient_funds: 'not sufficient funds',
        Metawords_Blind_Box: 'Metawords Blind Box',
        open: 'open',
        sold_out: 'Sorry, the blind box is sold out',
        lack_of_balance: 'Your account balance is insufficient',
    },
    buyback: {
        title: 'Select a Chapter NFT to apply for buyback',
        details: "*For more details, please check out our buyback offers",
        Submit: 'Submit',
        normal_chapter: "Common Chapter NFT",
        advanced_chapter: "Premium Chapter NFT",
        No_Chapter_NFT_found: `Seems like you don't have a Chapter NFT...`,
        not_found_details1: `You can assemble a Chapter NFT with your Shard NFTs.`,
        not_found_details2: 'Or you can buy one from the market!',
        not_found_details3: 'The current buyback event for Premium Chapter NFTs has ended.',
        not_found_details4: 'You can continue participating in the buyback event for Common Chapter NFTs!',
        Explore_the_Marketplace: 'Explore the Marketplace',
        buyback_confirm: `This Chapter NFT fits one of our buyback offers! By confirming you agree to send your NFT to us (Flying Lion Studio) in exchange of corresponding payments. You should be able to receive our payment in the end of the week. Are your sure to continue the process?`,
        NFT_confirm: 'Yes,send my NFT',
        NFT_cancel: 'No,I want to keep it for now',
        NFT_received: 'We have received your Chapter NFT!',
        NFT_received_hint: '*You will receive our payment by the end of the week.',
        OK: 'OK',
        rule: 'You can only select one Chapter NFT at a time to apply for buyback.'

    },
    myShard: {
        No_items_found: 'No items found',
        hint1: `Seems like you don't have anything here yet`,
        hint2: 'Explore our market and find your favourite NFTs!',
        Explore_Marketplace: 'Explore Marketplace'
    },
    compound: {
        asse_fail: "Assembly Failed",
        asse_succ: "Assembly Succeed ",
        Back: 'Back',
        Chapter_Preview: 'Chapter Preview',
        common_chapter: 'Assemble into Common Chapter NFT',
        premium_chapter: 'Assemble into Premium Chapter NFT',
        assembling_common: 'Common Chater NFT Assembling',
        assembling_premium: 'Premium Chater NFT Assembling',
        assembling_common_hint: `You are using 5 Shard NFTs to assemble a Common Chapter 11AM. Shard NFTs used for assembling will be destroyed afterwards.Do you want to continue the process`,
        assembling_premium_hint: 'You are using 5 Shard NFTs to assemble a Premium Chapter 11AM. Shard NFTs used for assembling will be destroyed afterwards.Do you want to continue the process'
    },
    page: {
        put_prod_shel: "Put your product on the shelves",
        ente_pric_pric: "Enter price for one piece",
        plea_sele_type: "Please select type",
        view_the_item: "View The Item",
        mark_cap: "Market Cap",
        trends: "Trends",
        sele_cast_type: "Select casting type",
        plea_sele_modu: "Please select a module",
        sele_conf: "Select configuration",
        please_sele_chap: "Please select a chapter",
        plea_sele_frag: "Please select fragment number",
        comm: "Common",
        seni: "Senior",
        cenc: "Cencel",
        go_page: "Go",
        plea_sele_char: "Please select character number",
        see: "See",
        top_page: "top",
        bottom: "bottom",
        brow_deos_supp: "Your browser does not support video playback",
        paym_time_wait: "Payment time is not up, please wait",
        plea_prod_name: "Please enter the product name",
        pric_must_nume: "Price must be a numeric value",
        plea_prid_desc: "Please enter product description",
        leng_betw_3_char: "The length is between 3 and 15 characters",
        sale_star: "Sale starts on",
        asse_shar_chap: "Assemble Shard NFTs for a Chapter NFT",
        asse_amou_shar: "If this is your first-time assembly on Lion NFT, we will require authorization to access your Shard NFTs and initiate the transaction process, which will require a one-time gas fee.",
        // meta_conf_proc: "MetaWords 11AM. Confirm to proceed?",
        authori: "Authorized",
        page_appr: "Approve",
        page_conf: "Confirm",
        rece_your_shar: "We have received your Shard NFTs. You can find",
        chap_accu_shor: "the new Chapter NFT in your account shortly.",
        you_are_asse: "You are assembling",
        amou_shar_for: "(amount of) Shards for",
        shar_asse_perm: "* Shard NFTs used in the assembling process will be removed from your Shard NFT collection afterwards.",
        data_acqu_fail: "Data acquisition failed",
        no_not_dupl: "Do not select duplicate",
        parm_error: "Parameter error",
        you_do_perm: "You do not have permission",
        comm_name_cano: "Commodity name cannot be empty",
        maxi_deci_plac: "Enter a maximum of 4 decimal places for the price",
        prod_desc_empt: "Product description cannot be empty",
        curr_work_exis: "The current work already exists and cannot be re cast",
        oper_fail: "The operation failure",
        back_to_list: "Back to the list",
        asse_shar_tip_1: "You are assembling ",
        asse_shar_tip_2: " Shards for a ",
        asse_shar_tip_3: " Shards used for assembling will be destroyed. Do you confirm to proceed?",
        buy: "Buy",
        sell: "Sell",
        create: "Create"
    },
    verify: {
        success: "Verified",
        warning: "Pending",
        danger: "Verification failed",
        normal: "To be verified",

    },
    errCode: {
        // 网站自定义错误码 规则:1- ,01- ,01-
        10101: "没有安装metamask",
        10102: "当前钱包不是metamask",
        10103: "请将链网环境设置为主网",
        10104: "登录metamask并连接到平台",
        10105: "当前连接钱包非用户绑定钱包",
        10107: "请将链网环境切换到BSC主链",
        10108: "请登录",

        // 合约错误码
        30101: "地块状态异常",
        30102: "余额不足",
        30103: "交易失败",
        30104: "身份权限异常",
        30105: "输入地址异常",
        30106: "地块不存在",
        30107: "地块已铸造",

        // Metamask  EIP-1193规则
        4000: "MetaMask界面已弹出,请登录",
        4001: "用户拒绝",
        4100: "Unauthorized",
        4200: "The Provider does not support the requested method",
        4900: "The Provider is disconnected from all chains",
        4901: "The Provider is not connected to the requested chain.",
        "-32002": "Resource unavailable.",

        99999: "未知错误",

    },
    metaWordsValue: {
        rank: 'Ranking',
        item: 'Item',
        last_price: 'Last price',
        art_work_rewards: 'Art work rewards',
        tatal_val: 'Tatal Value',
        art_usering: 'Art works using the character',
        item_search: 'Search by the name of item',
        words_value: 'MetaWords Character NFTs Rewards',
        sell_all: 'See all'
    },
    kycAuthenTion: {
        title: 'If you have any questions about KYC, you can send an email to our mailbox cs @lionnft.io.',
        go_back: 'Personal Verification',
        info: 'Identity Information',
        nati: 'Nationality',
        choose_country: 'Choose Country',
        id: 'ID',
        type: 'Type',
        id_card: 'ID Card',
        passport: 'Passport',
        enter_id_number: 'Enter ID number',
        birthdate: 'birthdate',
        choose_date: 'choose date',
        name: 'Name',
        gender: 'Gender',
        male: 'Male',
        female: 'Female',
        upload_id_card: 'Upload Image of ID Card',
        good: 'Good',
        incomplete: 'Incomplete',
        blur: 'Blur',
        reflective: "Reflective",
        upload_detail: 'Please upload the valid ID. Please ensure that the four corners are complete, photo is clear whithout reflection.',
        declaration: 'Declaration',
        check: 'Please check the following declaration options：',
        agree: 'I have read and agreed to the',
        is_usa_citizen: 'I am not a US citizen and confirmed that the above information is true.',
        is_auther: 'I have other nationality or tax residence in other countries (if applicable)',
        no: 'No',
        yes: 'Yes',
        secondary_nationality: 'Secondary Nationality',
        choose_country: 'Choose Country',
        save: 'Save',
        submit: 'Submit',
        close: 'Close',
        pc_country: 'Please Choose Country!',
        pe_idnumber: 'Please enter ID number!',
        pc_idtype: 'Please select ID type!',
        pc_date: 'Please Choose Date!',
        pe_name: 'Enter Name!',
        pc_gender: 'Please Choose Gender!',
        up_idcard: 'Please upload the valid ID',
        ck_option: 'Please check the following declaration options',
        an_nation: 'Please choose another nationality',

    }
};