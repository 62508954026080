const getters = {
  language: state => state.app.language,
  classifyID: state => state.app.classifyID,
  userInfo: state => state.user.userInfo,
  params: state => state.creation.params,
  isOwner: state => state.creation.params.isOwner,
  loginState:state => state.user.userInfo.loginState,
  token:state => state.user.userInfo.token,
};
export default getters;
