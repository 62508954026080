import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)
// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
    meta: { header: true, showBot: true, login: false }
  },
  // 登录模块
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/login/Login.vue'),
    meta: { header: false, showBot: false, login: false },
  },
  {
    path: '/home',
    name: 'Home',
    meta: { header: true, login: false, parentRouteName: '' },
    component: () => import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
    children: [
      //字符管理
      //字符列表
      {
        path: '/wordsManage',
        name: 'WordsManage',
        meta: { title: '字符管理', parentRouteName: '', header: false, showBot: false, login: false },
        redirect: '/wordsList',
        component: () => import(/* webpackChunkName: "home" */ '../views/Layout/index.vue'),
        children: [
          {
            path: '/wordsList',
            name: 'WordsList',
            component: () => import(/* webpackChunkName: "login" */ '../views/wordsMange/wordsList/index.vue'),
            meta: { title: '字符列表', parentRouteName: 'WordsManage', header: false, showBot: false, hidden: false, login: false },

          },
          {
            path: '/wordsKinds',
            name: 'WordsKinds',
            component: () => import(/* webpackChunkName: "login" */ '../views/wordsMange/wordsKinds/index.vue'),
            meta: { title: '字符分类', parentRouteName: 'WordsManage', header: false, showBot: false, hidden: false, login: false },
            children: [
            ]
          },
          {
            path: '/wordsAdd',
            name: 'WordsAdd',
            component: () => import(/* webpackChunkName: "login" */ '../views/wordsMange/wordsAdd/index.vue'),
            meta: { title: '字符分类', parentRouteName: 'WordsManage', header: false, showBot: false, hidden: true },
          }
        ]
      },
      {
        path: '/creatManage',
        name: 'CreatManage',
        redirect: '/creatContent',
        meta: { title: '创作管理', parentRouteName: '', header: false, showBot: false, login: false },
        component: () => import(/* webpackChunkName: "home" */ '../views/Layout/index.vue'),
        children: [
          {
            path: '/creatContent',
            name: 'CreatContent',
            component: () => import(/* webpackChunkName: "login" */ '../views/creatManage/creatContent/index.vue'),
            meta: { title: '创作内容', parentRouteName: 'CreatManage', header: false, showBot: false, hidden: false, login: false },

          },
          {
            path: '/creatDetail',
            name: 'CreatDetail',
            component: () => import(/* webpackChunkName: "login" */ '../views/creatManage/creatDetail/index.vue'),
            meta: { title: '详情', parentRouteName: 'CreatManage', header: false, showBot: false, hidden: true },
          }
        ]
      },
      //字符管理
      //系统管理
      {
        path: '/systemManage',
        name: 'SystemManage',
        redirect: '/creatContent',
        meta: { title: '系统管理', parentRouteName: '', header: false, showBot: false, login: false, },
        component: () => import(/* webpackChunkName: "home" */ '../views/Layout/index.vue'),
        children: [
          {
            path: '/countManage',
            name: 'CountManage',
            component: () => import(/* webpackChunkName: "login" */ '../views/systemManage/countManage/index.vue'),
            meta: { title: '账号管理', parentRouteName: 'SystemManage', header: false, showBot: false, hidden: false, login: false, admin: false },
          },
          {
            path: '/personCenter',
            name: 'PersonCenter',
            component: () => import(/* webpackChunkName: "login" */ '../views/systemManage/personCenter/index.vue'),
            meta: { title: '个人中心', parentRouteName: 'SystemManage', header: false, hidden: true, showBot: false, },
          },
        ]
      },
    ]
  },

]

const router = new VueRouter({
  mode: 'hash',
  routes
})

//前置守卫beforeEach(guard)
router.beforeEach((to, from, next) => {
  // 判断访问的前置条件
  const loginState = store.getters.loginState;
  // console.log(loginState);
  if (loginState) {
    next();
  } else {
    if (to.name == 'Login') {
      next();
    } else {
      next({ name: 'Login' });
    }
  }

})

export default router
