export default {
    lang: {
        Language: "语言",
        zh: "中文",
        en: "English"
    },
    example: {
        detailedTutorial: "详细教程",
        dateFormatting: "日期格式化"
    },
    header: {
        switchText: '切换到另一个钱包',
        connectionText: '连接钱包',
    },
    login: {
        Register: '注册LionNFT',
        Register_tips: '通过您的邮箱来注册账户',
        Login: '登录LionNFT',
        Email_label: '邮箱',
        Email_placholder: '请输入您的邮箱地址',
        Password_label: '密码',
        Password_placholder: '请输入您的密码',
        Password_forgot: '忘记密码？',
        Valid: '请输入验证码',
        Code_label: '验证码',
        Code_placholder: '请输入验证码',
        Code_get: '获取验证码',
        Sign: '登录',
        Tips: '没有账号？',
        ToRegister: '去注册',
        Reset_title: '重置密码',
        Reset_tips: '输入您的电子邮件地址来重新设置密码',
        Reset_button: '重置',
        Register_button: '注册LionNFT账户',
        i_have_read: "我已阅读并同意服务条款和许可政策",
        and_the_lice: "可政策",
        plea_inpu_addr: "请输入您的邮箱地址",
        plea_vali_addr: "请输入您的有效的邮箱地址",
        plea_veri_code: "请输入您的验证码",
        code_is_char: "字符代码为4个",
        plea_ente_pass: "请输入您的密码",
        plea_read_firt: "请先阅读并同意条款",
        Privacy_Notice: '隐私政策',
        back: "返回",
        account_is_not_exist: '该账户不存在',
        e_wallet: '请先登录电子钱包',
        The_wallet_is_in_use: '钱包已被使用，请换个新的钱包'
    },
    home: {
        home: "首页",
        search: "搜索",
        Marketplace: "市场",
        Account: "帐户",
        Create: "创造",
        metawords: "地书",
        LAN: "BSC 主网",
        LoginWallet: "登录",
        title: "发现、收集和销售非凡的 NFT",
        content: "在世界上最大的NFT市场之一",
        buttons: "创造",
        NameSwiper: "独家元世界掉落",
        author: "畅销书作家",
        introduce: "创建和出售您的 NFT",
        wallet: "钱包",
        walletintroduce: "设置好您选择的钱包后，单击右上角的钱包图标将其连接到 Meta World。",
        collect: "创建您的收藏",
        collectintroduce: "编辑您的社交链接、描述、个人资料和背景图片，单击创建设置您的作品集，并设置销售费用。",
        establish: "添加您的 NFT",
        establishintroduce: "上传您的作品（图像、视频、音频或 3D 艺术作品），添加标题和描述，并使用属性、统计数据和可解锁内容自定义您的 NFT。",
        sale: "列表出售",
        saleintroduce: "在拍卖和固定价格列表之间进行选择。 您选择出售 NFT 的方式，我们帮助您出售它们!",
        bazaar: "探索浏览",
        Invitealliance: "邀请加盟",
        email: "如果您和您的团队想要发布和推送 NFT，请联系 service@lionnft.io",
        bottom1: "关于我们",
        bottom2: "白皮书",
        bottom3: "审计报告",
        bottom4: "公司背景",
        bottom5: "服务条款",
        bottom6: "隐私政策",
        bottom7: "法律声明",
        bottom8: "网站导航",
        bottom1_href: "https://www.yuque.com/docs/share/0184e12d-bc74-49bc-9c2c-8e6d3f985920?#",
        bottom2_href: "#",
        bottom3_href: "#",
        bottom4_href: "https://www.yuque.com/docs/share/5bb26bff-3c55-4163-b821-0a73b001faa4?#",
        bottom5_href: "https://www.yuque.com/docs/share/507251e4-b60f-48cf-87b2-536300c64c33?#",
        bottom6_href: "https://www.yuque.com/docs/share/3292bf2d-7c2d-43ac-9289-06db36598125?#",
        bottom7_href: "https://www.yuque.com/docs/share/3ec50b5e-c72e-4999-99b4-40f2806314a1?#",
        bottom8_href: "#",
        view: "浏览",
        items: "物品",
        owners: "持有者",
        floo_pric: "地板价",
        high_pric: "最高价",
        xubind_describe: "MetaWords的字符继承了地书累积的所有标识，被铸造成NFT后作为MetaWords语言体系当中最小组成单位，即文字系统中的单独的文字。徐冰自2003年开始从全球收集象形符号直至今日，每个符号都经历了时代的变化，经过徐冰的不断修改与调整，确认符合MetaWords整体语言体系与风格之后，最终收录到MetaWords词库当中成为字符NFT。",
        verified: "已认证",
        my_prof: "个人中心",
        my_shar: "我的碎片",
        Inhabitant_program: "原住民计划",
        log_out: "登出",
        meta_char_nft: "MetaWords 字符 NFTs",
        original_nft: "MetaWords 原创 NFT",
        char_desc: "MetaWords语言体系中最小的组成单位",
        meta_shar_nft: "MetaWords 碎片 NFTs",
        trad_exce_draw: "交易徐冰《地书》的章节碎片",
        comi_soon: "敬请期待",
        meta_orig_nft: "MetaWords 原创 NFTs",
        crea_orig_nft: "使用MetaWords字符NFT创作属于您自己的NFT作品，解锁MetaWords语言世界的新可能!",
        meta_blin_boxe: "MetaWords盲盒",
        in_each_meta: "在每个盲盒中，您将有可能获得以下两种NFT：",
        a_meta_char: "一个MetaWords字符NFT.",
        the_sing_char: "MetaWords字符NFT是MetaWords语言体系中最小组成单位",
        or: "或",
        a_meta_shar: "一个MetaWords碎片NFT.",
        a_shor_piece: "碎片NFT是以徐冰《地书》原著中的章节片段拆分而来。碎片NFT在未来可以组成章节来还原《地书》中的经典场景。",
        left: "剩余",
        purc_link: "购买链接 即将上线",
        join_our_disc: "欢迎加入我们的Telegram获取更多信息",
        crea_your_meta: "创作属于您自己的MetaWords原创NFT",
        resour_for_gett: "了解更多信息",
        meta_mark: "MetaWords市场",
        lion_nft_rese: "LionNFT 2021，版权所有.",
        suipian_nft: `MetaWords碎片NFT是艺术家徐冰的艺术作品"地书：从点到点"在元宇宙当中的全新表达形式。 该书讲述了一个普通白领一天24个小时的生活。碎片NFT来自35个章节，这些章节对应的是"地书：从点到点"当中的24个小时，每个章节都有属于自己的碎片NFT。 最终，这些地书碎片NFT将组装成一个完整的地书NFT，一个源自作品"地书"的全新的NFT艺术作品。`,
        yuanchuang_nft: `“MetaWords原创NFT” 是用户基于 MetaWords 语言系统，使用创作工具原创的NFT。创作工具中包含了所有已发售的MetaWords字符,并且随着项目的进展，用户可使用的字符会越来越丰富。平台在用户使用创作工具时不收取任何费用，确保每一位元宇宙的原住民都可以接触并使用MetaWords文字体系。`,


    },
    Marketplace: {
        Characters: 'MetaWords 字符 NFT',
        Shards: 'MetaWords 碎片 NFT',
        Create: 'MetaWords 原创 NFT'
    },
    bazaar: {
        title: "浏览",
        jiazai: "加载更多",
        meiyou: "没有更多了",
        no_data_baza: "暂无数据",
    },
    personalCenter: {
        edit: "编辑个人信息",
        creaTed: "我铸造的",
        bought: "我的持有 ",
        favorites: "我喜爱的",
        sold: "下架商品",
        waitPay: "待付款的",
        onsale: "我在销售的",
        blindBox: "我的盲盒",
        history: "我的交易记录",
        collected: '我持有的',
        kyc: 'KYC已认证',
        even_type: "活动类型",
        all: "所有类型",
        purc: "买入",
        sole: "卖出",
        offe: "出价",
        paym: "付款",
        mint: "铸造",
        list: "上架",
        even: "活动",
        item: "物品",
        pric: "价格",
        from: "来自",
        to_per: "去往",
        date_per: "日期",
        name_cann_empt: "名称不可为空",
    },
    establish: {
        sure: "确定",
        go: "回退",
        Create: "创造商品",
        jieshao: "如果您希望您的收藏品独一无二，请选择“单个”，如果您想多次出售一个收藏品，请选择“多个”",
        dan: "单个",
        duo: "多个",
        weiqi: "铸造围棋",
        zijin: "我们不拥有您的私钥，未经您的确认无法访问您的资金",
        tishi: "提示",
        tihsi1: "1.下载SGF文件方可进行下一步",
        tishi2: "2.点击'跳过'上传自己的SGF文件",
        quxiao: "跳过",
        xiayibu: "下一步",
        dishu: "地书",
        dishuTitle: "铸造 MetaWords NFT",
        type_select: '请选择所属类别',
        please_select: '请选择',
        tags: "添加标签",
        addTag: "添加标签"
    },
    Single: {
        fanhui: "返回上一级",
        qipu: "创建棋谱NFT",
        dan: "创建单个收藏",
        duoge: "创建多个收藏",
        shangchuan: "上传文件",
        yulan: "预览",
        xuanze: "选择文件",
        toufang: "投放市场",
        yulan1: "上传文件以预览您全新的 NFT",
        shurujiage: "输入价格 允许用户立即购买您的NFT",
        jiesuo: "购买后解锁",
        tijiao: "确认铸造",
        price: "价格 (0为拍卖)",
        PleasePrice: "价格不可为空",
        Title: "标题",
        PleaseTitle: "请输入您的标题",
        Description: "描述",
        PleaseDescription: "请输入您的描述",
        Royalties: "版权税",
        Properties: "属性(可选)",
        Supply: "供给",
        Approve: "授权合约",
        Create: "创造您的NFT",
        Checking: "授权合约通过您的电子钱包进行交易",
        Done: "完毕",
        Progress: "进行中...",
        Start: "开始",
        toKen: "上传文件并铸造NFT",
        method: "申请调用智能合约。这个过程需要您支付一笔相对应的gas fee",
        order: "签署卖单",
        orderusing: "使用您的电子钱包签署卖单。如果您取消签署，那意味着您将放弃本次铸造，并且您在上一步支付的gas fee将无法得到退还",
        weiqiname: "围棋名称",
        heiqiname: "黑棋棋手",
        heiqiduanwei: "黑棋段位",
        baiqiname: "白棋棋手",
        baiqiduanwei: "白棋段位",
        PleaseWeiqiname: "围棋名称不可为空",
        Pleaseheiqiname: "黑棋棋手不可为空",
        Pleaseheiqiduanwei: "黑棋段位不可为空",
        Pleasebaiqiname: "白棋棋手不可为空",
        Pleasebaiqiduanwei: "白棋段位不可为空",
        titl_cann_empt: "标题不能为空",
        leng_betw_char: "长度在 2 到 32 个字符",
        file_uplo_cann: "待上传文件不能为空",
        plea_sele_cate: "请选择所属类别",
    },
    redactUser: {
        fanhui: "返回上一级",
        xuanze: "上传图片",
        recommend: "最小推荐图像大小400x400",
        Edit: "编辑个人信息",
        EditDescription: "你可以修改你的昵称以及上传你的头像",
        name: "我的昵称",
        PleaseName: "输入您的显示名称",
        URL: "专属URL",
        PleaseURL: "输入自定义URL",
        Bio: "个性介绍",
        PleaseBio: "用几句话介绍一下你自己",
        Update: "更新个人信息",
        img_describe: '只能上传jpg/png格式图片',
        Image_format_error: '图片格式错误',
        notName: '名称不可为空',
        length: '长度在 2 到 16 个字符'
    },
    details: {
        approve: "元世界认证",
        Owned: "拥有者",
        available: "可用的",
        creator: "创造者",
        own: "所有者",
        Transaction: "交易记录",
        user: "用户",
        time: "时间",
        perating: "操作",
        product: "商品描述",
        About: "关于 NFT",
        Approved: "认可的",
        approvedNoW: "立即批准",
        Biding: "投标",
        BidNow: "现在出价",
        Buying: "购买",
        BuyNow: "立即购买",
        UnSale: "已下架",
        OnSale: "上架",
    },
    dishu: {
        baseDS: "基础地书",
        mintDS: "铸造地书"
    },
    messages: {
        ltbalance: "无法处理付款：账户余额不足",
        acquiree: "商品已被购买",
        have_offer: "当前价格已有出价",
        reject: '用户拒绝交易',
        failure: '交易失败',
        mintingCompleted: '创建完成'
    },
    Identity: {
        Verification: "个人认证",
        describe: "请输入您的真实信息，否则您将无法通过认证",
        nati: "国籍",
        firs_name: "名字",
        last_name: "姓氏",
        ente_name: "请输入您的名字",
        ente_last_name: "请输入您的姓氏",
        cont: "继续",
        pass: "护照",
        id_numb: "证件号",
        card_id: "输入证件号",
        upload_imag_of: "上传您护照的数据页",
        submit: "提交",
        please_select_country: '请选择国家'
    },
    document: {
        type: "证件类型",
        describe: "请输入您的真实信息，否则您将无法通过认证",
        idcard: "政府颁发的身份证件"
    },
    passport: {
        upload_image: "上传护照数据页的图像",
        good: "合格",
        cropped: "被剪裁",
        Blurry: "图像模糊",
        descrie: "文件大小必须小于5MB，且为.jpg或.png格式。",
        image: "上传您护照的数据页",
        front: "上传证件正面",
        back: "上传证件背面",
        result: "身份验证",
        result_descrie: "我们将在24小时之内完成审核。审核结果将发送到您的邮箱。"
    },
    wallet: {
        address: "你的钱包地址",
        bind: "绑定",
        unbind: '解绑',
        bala: "余额",
        conv: "兑换",
        conn_with_meta: "连接MetaMask来安全地保管你的数字资产与加密货币",
        wall_conn_bina: "请连接MetaMask电子钱包并将链网环境设置为BSC主链",
        plea_main_netw: "请将链网环境切换到BSC主链.",
        plea_test_netw: "请切换到BSCTestnet网络!",
        hava_unlo_wall: "您尚未解锁BSC钱包!",
        wall_conn_succ: "您已成功连接钱包,请完成注册环节以继续使用网站",
        plea_swit_netw: "请切换到BSCMainnet网络!",
        copy: "复制",
    },
    convert: {
        conv_toke: "兑换",
        from: "您支付",
        amount: "请输入金额",
        receive: "您获得",
        receive_two: "您将获得的金额",
        convert_sure: "确认兑换",
        cane: "取消",
    },
    sell: {
        title: "选择您的出售方式",
        auction_describe: "出售给出价最高的用户",
        expiration_describe_one: "您的拍卖将在您设定的日期和时间自动停止。",
        expiration_describe_two: "最高出价者将拍得您的物品。不需要您手动取消此拍卖。",
        learn: "了解更多 ",
        learn_describe: '如果有人在拍卖的最后5分钟出价，拍卖将延长5分钟。',
        bid_deposit: "出价押金",
        Bid_Deposit_two: "出价押金",
        bid_deposit_describe: "您希望用户在出价时支付百分之多少的押金来保留他们的成交权",
        paytime: "结款时间",
        paytime_describe: "最终拍得您的物品的用户需要在您设置的时间内向您结款，否则对方将无法取回押金。",
        token_button: "合约已授权",
        shelf_time: "选择截止时间",
        day: "天",
        Set_Price: '定价',
        Set_the_price: "设定一个稳定价格或递减价格来出售",
        Sell_at_a_fixed_price: '设定一个固定的价格',
        Auction: '拍卖',
        Highest_Bid: '最高出价',
        Highest_Bid_describe: '拍卖给出价最高的人',
        Bundle: '打包出售',
        Bundle_describe: '和其他NFT捆包销售',
        Set_Price_describe: '除非此交易被取消或此资产被转移，此次上架出售将持续有效',
        Schedule_for_a_future_time: '预约上架时间',
        Schedule_for_a_future_time_describe: '您可以设定一个特定的日期与时间来上架销售您的物品。',
        Summary: '小计',
        Bid_Deposit: '出价押金',
        price: '价格',
        Approve_Meta: '允许',
        Minimum_Bid: '起拍价',
        Minimum_Bid_describe: '设置起拍价（起拍价必须大于等于0.01WBNB）',
        Expiration_Date: '截止时间',
        Bidding_up: '加价金额',
        Bidding_up_describe: '设置您的拍卖的最低加价金额（加价金额必须大于等于0.01WBNB）',
        Please_enter_the_content: '请输入内容',
        Post_listing: '上架出售',
        amou: "金额",
        your_bala: "您的账户余额:",
        curr_pric: "当前价格:",
        bidd_up: "最低出价:",
        chec_this_agre: "我已知悉并同意Lion NFT的",
        term_serv: "服务条款",
        subm_bid: "提交出价",
        canc: "取消",
        gift_title: "赠送",
        to_addr_nonull: "接收者地址不能为空",
        to_addr_iserror: "接收者地址为无效地址",
    },
    auction: {
        gift: '赠送',
        sell: '上架出售',
        market: "取消拍卖",
        approved: "已授权 WBNB",
        approve: "WBNB授权",
        place: "出价",
        event: '活动',
        price: '价格',
        from: '来自',
        to: '给到',
        date: '日期',
        you_maybe_like: '你可能还会喜欢:',
        remove_from_market: '从市场下架',
        place_bid: '购买',
        Description: '描述',
        Owned_by: '拥有者',
        Properties: '属性',
        Detail: '详情',
        Contract_Address: '合约地址',
        Token_ID: '代币ID',
        Token_Standard: '代币标准',
        Auction_Has_Ended: '已下架',
        Offers: '出价记录',
        Payment_time: '付款时间',
        crea_by: "创造者",
        Trading_History: '交易记录',
        Payment_is_due_in: '付款期限为:',
        Final_payment_time_ends_in: '最后付款时间为:',
        pay_now: "支付",
        plea_ente_amou: "请输入购买金额",
        bid_less_than: "出价不小于",
        plea_corr_form: "请输入正确格式金额",
    },
    footer: {
        ABOUT_US: '关于我们',
        AUDIT_REPORT: '审计报告',
        privacy: '隐私政策',
        terms: '条款',
        introduce: `Lion NFT市场平台主要专注于数字资产的投资与创新。Lion NFT可在受监管的情况下在全球范围进行数字资产兑换、NFT的创建、发行与交易等服务。`
    },
    hint: {
        loginSuccess: '登录成功',
        walletDifferent: "登录钱包与绑定钱包不一致",
        conversionSuccess: '转换成功',
        conversionFailure: '转换失败',
        formalError: '格式错误',
        amountError: '总数错误',
        successfullyPurchased: '购买成功',
        successfullyUnlisted: '下架成功',
        login: '用户未登录或着未绑定钱包',
        kyc: '请先完成KYC认证',
        walletErr: '请切换成您登陆时绑定的钱包地址',
        copyOK: '复制成功',
        not_bind_wallet: '未绑定钱包',
        not_login: '请先登录账户',
        networkId: '链网环境不一致'
    },
    prompt_language: {
        wall_succ: "钱包解绑成功",
        not_log: "未登录钱包",
        wall_bind_succ: "钱包绑定成功",
        wall_bound: "钱包已经绑定",
        log_succ: "登录成功",
        log_wall_not_bound: "登录钱包与绑定钱包不一致!",
        input_email: "请输入电子邮件地址",
        verifi_sent: "已发送验证码",
        register_was_succ: "注册成功",
        account_al_exis: "帐户已存在或验证代码错误",
        input_address: "请输入电子邮件地址",
        reset_succ: "重置成功",
        reset_faild: "重置失败",
        upload_faild: "更新失败",
        upload_succeeded: "更新成功",
        upload_waiting: "上传成功!等待认证!",
        no_valid_obtained: "未获得有效数据",
        unwallet: "未绑定钱包",
        copy_succ: "复制成功",
        credit_low: "余额不足",
        insuff_inven: "您已持有此商品",
        purc_succ: "购买成功",
        low_cert_un_pur: "认证级别低!无法购买!",
        ple_fil_corr: "请填写正确的竞拍价格",
        insu_acco_bala: "帐户WBNB余额不足",
        succ_bidd: "参与竞拍成功",
        inco_orde: "订单不正确",
        uscc_auct: "竞拍成功",
        sale_price_inva: "销售价格无效",
        conm_sale: "完成销售",
        plea_logi: "请登录",
        off_shel: "现成故障",
        agre_lion_term: "我同意LionNFT的服务条款",
        auth_deni: "拒绝授权",
        inco_pers_info: "个人信息不完整",
        no_perm: "不允许",
        error: "错误",
        dele_succ: "删除成功",
        uplo_form_does: "上传格式不符合要求",
        NFT_crea_whit: "无法创建NFT，因为它不在白名单上",
        only_auth_acco: "仅作者可以创作",
        ther_unfi_item: "还有未完成的项目",
        inco_book: "错误的图书类型",
        crea_comp: "创作完成",
        bid_no_less: "出价不低于",
        lang_bidd_cont: "地书竞拍合约未授权",
        pric_cann_be: "价格不能为空",
        crea_succ: "创建成功",
        off_shel_fail: "下架失败",
        remo_succ: "下架成功",
        agee_lion_term: "我同意LionNFT的服务条款",
        plea_sele_book: "请选择3个以上的书本元素",
        dupl_book_plea: "地书重复，请重新选择",
        avat_canno: "头像为空",
        coll_succ: "收藏成功",
        coll_fail: "收藏失败",
        plea_uplo_pict: "请将图片上传完成",
        plea_fill_info: "信息请填写完毕",
        wallet_addr_mony: "钱包地址不一致,不能进行金钱转换",
        error_10001: "已有交易者,不可下架",
        error_10500: "商品已下架不可购买",
        transaction: "交易失败",
        logi_fail: "密码输入错误",
        acco_not_exis: "账户输入错误或不存在",
        selectTime: "请选择预约上架时间",
        placeholder: "选择日期时间",
        lack_time: "您选择的预约上架时间不可使用",
        signature_failure: "您已取消该物品的上架出售",
        transaction_signature_failure: "您拒绝交易签名",
        failure: "操作失败",
        repeat_choose: "一个段落对应的碎片只能选择一个",
        canc_coll: "取消收藏",
        warn_tip: '请确认您的钱包内有WBNB余额,或者您可以点击右上角的"钱包"图标来兑换WBNB.',


    },
    table: {
        no_data: "暂无数据"
    },
    symbols: {
        filt: "筛选",
        clea_filt: "重置筛选",
        stat: "状态",
        fixe_pric: "定价销售",
        on_auct: "拍卖中",
        pric: "价格区间",
        appy: "提交",
        sear_crea_coll: "按创作者或类型搜索",
        pric_low_high: "按价格:从低到高",
        pric_high_low: "按价格:从高到低",
        newly_listed: "按时间:最新发布/上架",
        sort_orde: "排序方式",
        type_symb: "类型",
        results: '结果',
        min: "最小",
        max: "最多"
    },
    bind: {
        conn_your_wall: "连接你的钱包",
    },
    kyc_authentication: {
        cong: "恭喜!",
        acco_has_veri: "您的账户已经通过认证",
    },
    shards_page: {
        shard_title: "我的碎片",
        create_mana_shar: "创造、展示、管理、交易您的碎片NFT",
        shard: "碎片",
        chap: "章节",
        asse_into_norm: "合成章节NFT ",
        asse_into_prem: "合成高级章节NFT",
        appl_for_buyb: "申请回购",
    },
    operation: {
        back: "返回",
        preview: "预览",
        cont_comp_pict: "内容只允许图片组成",
        cast_char_less1: "您需要至少1个字符来进行铸造(不包含标点符号与版权字符)",
        cast_char_less2: "您需要至少4个字符来进行铸造(不包含标点符号与版权字符)",
        pict_load_fail: "图片加载失败",
        plea_ente_cont: "请输入内容",
        copi_char_exce: "复制字符超过最大上限",
        numb_copi_exce: "复制数量超过最大上限",
        cont_reac_maxi: "内容已达到最大上限",
        maxi_numb_char: "字符已达到最大上限",
        canc: "取 消",
        cast: "铸 造",
        first_trad_lion: "如果这是您第一次在Lion NFT平台进行交易，我们需要您授权平台来调用您的NFT并启动交易流程。此流程需要您支付一个一次性的gas fee",
    },
    blindBox: {
        received_order: '订单已提交!',
        purchase1: '是否确认使用 ',
        purchase2: ' BNB来购买1个MetaWords盲盒？',
        Buying_tips1: '感谢您的购买!',
        Buying_tips2: '平台正在处理您的订单',
        Buying_tips3: '请您稍等5分钟左右,您将可以在',
        Buying_tips4: '"个人中心>我的盲盒"页面找到您购买的盲盒.',
        Sure: '确认',
        Cancel: '取消',
        Okay: '好的!',
        Purchase_failed: '购买失败',
        Purchase_succeeded: '购买成功',
        blind_box_num: '盲盒数量不足',
        not_sufficient_funds: '余额不足',
        Metawords_Blind_Box: 'Metawords 盲盒',
        open: '打开盲盒',
        sold_out: '抱歉，盲盒已经卖光啦',
        lack_of_balance: '您的账户余额不足',
    },
    buyback: {
        title: '请选择一个章节NFT来申请回购',
        details: "*如果您想了解更多详情，请查看我们的回购规则",
        Submit: '提交',
        normal_chapter: "普通章节",
        advanced_chapter: "高级章节",
        No_Chapter_NFT_found: `您好像没有章节NFT...`,
        not_found_details1: '您可以使用您的碎片NFT来合成一个章节NFT.',
        not_found_details2: '您也可以在市场上购买一个您心仪的章节NFT!',
        not_found_details3: '当前高级章节的回购活动已截至,',
        not_found_details4: '欢迎继续参加普通章节的回购活动！',
        Explore_the_Marketplace: '逛逛市场',
        buyback_confirm: `这个章节符合我们的回购规则!通过点击确认,您同意将您的NFT转给我们(飞狮工作室)以换取相对应的回购金额。您会在本周结束前收到我们的付款。确认继续回购流程吗？`,
        NFT_confirm: '确认发送NFT',
        NFT_cancel: '取消',
        NFT_received: '我们收到了您的章节NFT!',
        NFT_received_hint: '*您将会在本周晚些时候收到我们的付款',
        OK: '好的',
        rule: '您一次只能选择一个章节NFT来申请回购'
    },
    myShard: {
        No_items_found: '没有找到NFT',
        hint1: '这里好像还什么都没有',
        hint2: '您可以逛逛我们的市场，寻找您心仪的NFT!',
        Explore_Marketplace: '逛逛市场'
    },
    compound: {
        asse_fail: "合成失败",
        asse_succ: "合成成功",
        Back: '返回',
        Chapter_Preview: '章节预览',
        common_chapter: '合成普通章节NFT',
        premium_chapter: '合成高级章节NFT',
        assembling_common_hint: `您正在使用5个碎片NFT来合成一个普通章节11AM。用于合成的碎片NFT将会被销毁。是否继续完成合成`,
        assembling_premium_hint: '您正在使用5个碎片NFT来合成一个高级章节11AM。用于合成的碎片NFT将会被销毁。是否继续完成合成'
    },
    page: {
        put_prod_shel: "把你的产品放到货架上",
        ente_pric_pric: "输入一件的价格",
        plea_sele_type: "请选择类型",
        view_the_item: "查看项目",
        mark_cap: "市值",
        trends: "趋势",
        sele_cast_type: "请选择铸造类型",
        plea_sele_modu: "请选择模块",
        sele_conf: "选择配置",
        please_sele_chap: "请选择章节",
        plea_sele_frag: "请选择碎片编号",
        comm: "普通",
        seni: "高级",
        cenc: "取消",
        go_page: "前往",
        plea_sele_char: "请选择字符编号",
        see: "查看",
        top_page: "顶部",
        bottom: "底部",
        brow_deos_supp: "您的浏览器不支持视频播放",
        paym_time_wait: "支付时间未到,下架请稍后...",
        plea_prod_name: "请输入商品名称",
        pric_must_nume: "价格必须为数字值",
        plea_prid_desc: "请输入商品介绍",
        leng_betw_3_char: "长度在 3 到 15 个字符",
        sale_star: "减价开始于",
        asse_shar_chap: "使用碎片NFT来合成普通章节NFT",
        asse_amou_shar: "如果这是您第一次在Lion NFT平台进行合成，我们需要您授权合约来调用您的NFT并启动交易流程。此流程需要您支付一个一次性的gas fee。",
        // meta_conf_proc: "MetaWords 11AM的合成。是否确认合成？",
        authori: "已授权",
        page_appr: "授权",
        page_conf: "确认",
        rece_your_shar: "我们已收到您的碎片NFT。你可以找到",
        chap_accu_shor: "新的NFT章节很快就会出现在您的帐户中。",
        you_are_asse: "您正在使用",
        amou_shar_for: "个碎片来进行普通章节NFT ",
        shar_asse_perm: "*如您确认，用于合成的碎片NFT将永久的从您的收藏中移除并销毁。",
        data_acqu_fail: "数据获取失败",
        no_not_dupl: "请勿选择重复的",
        parm_error: "参数有误",
        you_do_perm: "您暂无权限",
        comm_name_cano: "商品名称不能为空",
        maxi_deci_plac: "价格最多输入4位小数点",
        prod_desc_empt: "商品说明不能为空",
        curr_work_exis: "当前作品已存在不可重复铸造",
        oper_fail: "操作失败",
        back_to_list: "返回到列表",
        asse_shar_tip_1: "您正在使用",
        asse_shar_tip_2: "个碎片NFT来进行",
        asse_shar_tip_3: "的合成,用于合成的碎片NFT将会被销毁，是否继续完成合成?",
        buy: "买",
        sell: "卖",
        create: "创造"
    },
    verify: {
        success: "已认证",
        warning: "已提交在审核中",
        danger: "已申请被拒绝",
        normal: "尚未申请认证",
    },
    errCode: {
        // 网站自定义错误码 规则:1- ,01- ,01-
        10101: "没有安装metamask",
        10102: "当前钱包不是metamask",
        10103: "请将链网环境设置为主网",
        10104: "登录metamask并连接到平台",
        10105: "当前连接钱包非用户绑定钱包",
        10107: "请将链网环境切换到BSC主链",
        10108: "请登录",

        // 合约错误码
        30101: "地块状态异常",
        30102: "余额不足",
        30103: "交易失败",
        30104: "身份权限异常",
        30105: "输入地址异常",
        30106: "地块不存在",
        30107: "地块已铸造",

        // Metamask  EIP-1193规则
        4000: "MetaMask界面已弹出,请登录",
        4001: "用户拒绝",
        4100: "Unauthorized",
        4200: "The Provider does not support the requested method",
        4900: "The Provider is disconnected from all chains",
        4901: "The Provider is not connected to the requested chain.",
        "-32002": "Resource unavailable.",

        99999: "未知错误",

    },
    metaWordsValue:{
        rank:'排名',
        item:'物品',
        last_price:'最后成交价',
        art_work_rewards:'奖励',
        tatal_val:'全部收益',
        art_usering:'使用字符的作品',
        item_search:'按字符名称搜索',
        words_value:'MetaWords 字符收益',
        sell_all:'查看全部'
    },
    kycAuthenTion:{
        title:'关于kyc如果您有任何问题，您可以发送邮件到 cs@lionnft.io 把您的问题告诉我们。',
        go_back:'认证账号',
        info:'身份信息',
        nati:'国籍',
        choose_country:'请选择国家',
        id:'身份证件',
        type:'请选择证件类型',
        id_card:'身份证',
        passport:'护照',
        enter_id_number:'请输入证件号码',
        birthdate:'生日',
        choose_date:'请选择生日',
        name:'姓名',
        gender:'性别',
        male:'男',
        female:'女',
        upload_id_card:'上传资料',
        good:'示例',
        incomplete:'不完整',
        blur:'模糊',
        reflective:"反光",
        upload_detail:'请上传有效期内的身份证件，确保边框完整、文字清晰、无反光：',
        declaration:'声明',
        check:'请勾选以下声明选项：',
        agree:'我（用户）已充分阅读并同意',
        is_usa_citizen:'我（用户）不是美国公民，并确认上述信息真实无误',
        is_auther:'我有其他国籍或税务居民身份（如果适用）',
        no:'否',
        yes:'是',
        secondary_nationality:'第二国籍:',
        choose_country:'请选择国家',
        save:'暂存',
        submit:'提交',
        close:'关闭',
        pc_country:'请选择国家！',
        pe_idnumber:'请输入证件号码！',
        pc_idtype:'请选择证件类型！',
        pc_date:'请选择日期！',
        pe_name:'请输入姓名！',
        pc_gender:'请选择性别！',
        up_idcard: '请上传有效证件',
        ck_option: '请勾选以下声明',
        an_nation: '请选择是否有其他国籍',
      }

};