import router from '../router/'
import store from '@/store'
import _ from "lodash";
import i18n from '@/lang';
import { Message } from 'element-ui'
/*
 NOTE 全局公用方法


*/
// 全局metamask环境检测
// 不同环境下的链路ID


// 全局路由跳转
async function gotoRoute(pathName, params) {
  if (pathName == "") {
    router.go(-1);
  } else {
    let name
    if (pathName == '/') {
      name = "Home"
    } else {
      name = _.capitalize(pathName);
    }
    router.push({ name: name, params: { ...params } });
  }
}



// 重写message组件 并限制每次只弹出一个
let messageInstance = null;
const message = options => {
  if (messageInstance) {
    messageInstance.close();
  }
  Message.closeAll();
  messageInstance = Message(options);
  // return messageInstance;
};
//重写方法
['success', 'warning', 'info', 'error'].forEach(type => {
  message[type] = options => {
    options = { message: options, offset: 80 };
    options.type = type;
    return message(options);
  };
});







// 身份认证状态判定

// 全局调用 sessionStorage、localStorage
// sessionStorage
function s_getItem(key) {
  return window.sessionStorage.getItem(key);
}
function s_setItem(key, value) {
  window.sessionStorage.setItem(key, value);
}
function s_removeItem(key) {
  window.sessionStorage.removeItem(key);
}
function s_clear() {
  window.sessionStorage.clear();
}

// localStorage
function l_getItem(key) {
  return window.localStorage.getItem(key);
}
function l_setItem(key, value) {
  window.localStorage.setItem(key, value);
}
function l_removeItem(key) {
  window.localStorage.removeItem(key);
}
function l_clear() {
  window.localStorage.clear();
}



// 转换token_id
export function conversion(id, number) {
  if (!id || !number) {
      return '#0/0';
  }
  const seg_id = id.toString().substring(8);
  return `#${parseInt(seg_id)}/${parseInt(number)}`;

}

export default {
  gotoRoute,
  message,
  s_getItem,
  s_setItem,
  s_removeItem,
  s_clear,
  l_getItem,
  l_setItem,
  l_removeItem,
  l_clear,
  conversion
}