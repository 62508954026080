import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false
// 全局公用style
import "./assets/style/common.less";
// 文本格式化插件
import format from 'vue-text-format';
Vue.use(format);
// 饿了么 UI
import Element from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(Element);

// 国际化语言
import i18n from "./lang"; // internationalization

// lodash 插件
import _ from "lodash";
Vue.prototype.$lodash = _;

// 全局公用方法
import init from "@/utils/init";
Vue.prototype.$init = init;
// 重写message组件 并限制每次只弹出一个

Vue.prototype.$message = init.message;
// 导入 api接口 并挂载全局
import api from "@/utils/api";
Vue.prototype.$api = api;

// 全局校验文件
import validate from '@/utils/validate';
Vue.prototype.$validate = validate;
import pdf from 'vue-pdf'
Vue.use(pdf)

import VueCountdown from '@chenfengyuan/vue-countdown';
Vue.component(VueCountdown.name, VueCountdown);
new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
